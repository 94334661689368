<template>
  <div class="shared-documents">
    <div>
      <drag-n-drop-upload
        :activeRole="activeRole"
        :categoryOptions="categoryOptions"
        @fetchDocuments="fetchDocuments"
        :loadedEmployees="loadedEmployees"
        uploadType="shared"
      />
      <div class="shared-documents__table mb-5">
        <v-card class="shared-documents__table-card" :class="{ 'v-card--no-border': $vuetify.breakpoint.smAndDown }">
          <v-card-text
            class="border-top border-left border-right"
            :class="[{ 'border--dark': !$vuetify.breakpoint.smAndDown }]"
          >
            <div class="text--primary shared-documents__table-heading">
              {{ $t('myBavSearchNFilter') }}
            </div>
          </v-card-text>
          <v-card-text
            class="d-flex align-center flex-wrap pb-0 border-left border-right"
            :class="[
              { 'px-2': $vuetify.breakpoint.smAndDown },
              { 'mb-2': $vuetify.breakpoint.smAndDown },
              { 'border-bottom': $vuetify.breakpoint.smAndDown },
              { 'border--dark': !$vuetify.breakpoint.smAndDown },
            ]"
          >
            <v-row>
              <v-col cols="12" :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }" class="px-0 d-flex filter">
                <v-text-field
                  v-model="searchQuery"
                  @input="searchData"
                  single-line
                  dense
                  outlined
                  hide-details
                  clearable
                  :append-icon="icons.mdiMagnify"
                  :label="$t('myBavSearchLabel')"
                  :placeholder="$t('myBavSearchLabel')"
                  :class="{
                    'mb-6 shared-documents__table-filter': !$vuetify.breakpoint.smAndDown,
                  }"
                  class="px-4"
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-select
                  v-model="categoryFilter"
                  :items="categoryOptions"
                  @change="setCategory"
                  single-line
                  outlined
                  dense
                  hide-details
                  clearable
                  :label="$t('myBavCategoryFilter')"
                  :placeholder="$t('myBavCategoryFilter')"
                  :class="{
                    'mb-6  pr-0 shared-documents__table-filter': !$vuetify.breakpoint.smAndDown,
                  }"
                ></v-select>

                <v-select
                  v-model="statusFilter"
                  :items="statusOptions"
                  single-line
                  outlined
                  @change="setStatus"
                  dense
                  hide-details
                  placeholder="Select Status"
                  :class="{
                    'shared-documents__table-filter': !$vuetify.breakpoint.smAndDown,
                  }"
                  class="mb-5 px-4"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-data-table
            :key="rerenderKey"
            v-model="selectedDocs"
            :headers="headers"
            :items="loadedDocuments"
            :mobile-breakpoint="0"
            :server-items-length="totalLoadedDocuments"
            :loading="loading"
            :footer-props="{
              'items-per-page-options': [15, 30, 50, 100],
              'items-per-page-text': $t('myBavDocumentsPerPage'),
            }"
            :sort-by.sync="sortingOptions"
            :sort-desc.sync="sortingIsDesc"
            @update:items-per-page="setLimit"
            @pagination="handlePagination"
            @click:row="(row) => download(row.id)"
            class="text-no-wrap cursor-pointer"
            :item-class="setRowClass"
          >
            <template #[`header.trending`]>
              <v-icon size="22">
                {{ icons.mdiTrendingUp }}
              </v-icon>
            </template>
            <template #[`item.name`]="{ item }">
              <span class="text-no-wrap">{{ item.name }}</span>
            </template>

            <template #[`item.category`]="{ item }">
              <span>{{ getDocumentCategoryTranslation(item.category) }}</span>
            </template>

            <template #[`item.employee`]="{ item }">
              <div class="d-flex align-center">
                <a @click.stop.prevent="$router.push('/profile/' + item.employee.id)">
                  {{ item.employee.firstName }}&nbsp;{{ item.employee.lastName }}
                </a>
              </div>
            </template>

            <template #[`item.createdAt`]="{ item }">
              <span class="text-xs text-no-wrap now d-none">{{ $t('uploadDocumentNow') }}</span>
              <span class="text-xs text-no-wrap">
                {{ moment.utc(item.createdAt).local().format('DD.MM.YYYY') }} /
                {{ moment.utc(item.createdAt).local().format('HH:mm') }} Uhr
              </span>
            </template>

            <!-- actions -->
            <template #[`item.actions`]="{ item }">
              <div class="d-flex justify-center pr-0">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      size="16"
                      class="mr-6"
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="download(item.id)"
                    >
                      {{ icons.mdiDownload }}
                    </v-icon>
                    <!-- <span>{{ $t("download") }}</span> -->
                  </template>
                  <span>{{ $t('download') }}</span>
                </v-tooltip>
              </div>
            </template>

            <template slot="no-data">
              <div class="my-5">
                <p>{{ $t('myBavNoResults') }}</p>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </div>

    <!-- edit  -->
    <ModalWindow :is-open="editDocumentsModal" :title="$t('myBavEditModalTitle')" :stacked="true" :maxWidth="400">
      <template #content>
        <div v-if="documentToEdit" :class="{ 'px-5': $vuetify.breakpoint.smAndDown }">
          <v-text-field
            v-model="documentToEdit.name"
            :label="$t('myBavUploadModalDocLabel')"
            :placeholder="$t('myBavUploadModalDocLabel')"
            dense
            readonly
            outlined
          ></v-text-field>
          <v-select
            v-model="documentToEdit.category"
            :items="categoryOptions"
            :label="$t('myBavUploadModalCategoryLabel')"
            :placeholder="$t('myBavUploadModalCategoryLabel')"
            dense
            outlined
          ></v-select>
        </div>
      </template>
      <template #actions>
        <v-btn block color="primary" @click="confirmEdit">
          {{ $t('buttonConfirm') }}
        </v-btn>
        <v-btn outlined block color="primary" class="ml-0 mt-3" @click="editDocumentsModal = false">
          {{ $t('buttonCancel') }}
        </v-btn>
      </template>
    </ModalWindow>
    <!-- archive  -->

    <ModalWindow :is-open="archiveDocumentsModal" warning>
      <template #content>
        <p v-if="selectedDocs.length > 0" class="text-base">
          {{ $t('myBavRecycleBinConfirmationMultiple') }}
        </p>
        <p v-else class="text-base">{{ $t('myBavRecycleBinConfirmation') }}</p>
      </template>
      <template #actions>
        <v-btn outlined color="primary" @click="archiveDocumentsModal = false">
          {{ $t('buttonCancel') }}
        </v-btn>
        <v-btn color="primary" @click="confirmArchive">
          {{ $t('buttonConfirm') }}
        </v-btn>
      </template>
    </ModalWindow>

    <!-- delete  -->

    <ModalWindow :is-open="deleteDocumentsModal" error>
      <template #content>
        <p v-if="selectedDocs.length > 0" class="text-base">
          {{ $t('myBavPermanentDeletionConfirmationMultiple') }}
        </p>
        <p v-else class="text-base">
          {{ $t('myBavPermanentDeletionConfirmation') }}
        </p></template
      >
      <template #actions>
        <v-btn outlined color="primary" @click="deleteDocumentsModal = false">
          {{ $t('buttonCancel') }}
        </v-btn>
        <v-btn color="primary" @click="confirmDelete">
          {{ $t('buttonConfirm') }}
        </v-btn>
      </template>
    </ModalWindow>
  </div>
</template>

<script>
import {
  mdiTrendingUp,
  mdiPlus,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiPencil,
  mdiDownload,
  mdiInformationOutline,
  mdiMagnify,
  mdiInformation,
  mdiDelete,
} from '@mdi/js';

import { required } from '@core/utils/validation';

import moment from 'moment';
import { debounce } from 'lodash';

import { ref, getCurrentInstance, onMounted, computed, watch } from '@vue/composition-api';
import { categorizeDocument, downloadDocument, archiveDocument, deleteDocument } from '@/api/document';
import {
  DOC_CUSTOMER_INFO,
  STATUS_ACTIVE,
  STATUS_DELETED,
  EMPLOYEE_CATEGORIES,
  CATEGORY_TRANSLATION_MAP,
  ROLE_KEY_ACCOUNT,
  DOCUMENT_SECURITY_SCAN_STATUS_TRANSLATION_MAP,
  ROLE_BAV_MANAGER,
} from '@/constants';
import DragNDropUpload from '@/components/upload/DragNDropUpload';
import ModalWindow from '@/components/modal/ModalWindow';

export default {
  name: 'SharedDocumentsDataTable',
  components: { DragNDropUpload, ModalWindow },
  setup() {
    const vm = getCurrentInstance().proxy;
    const currentPage = ref(1);
    const rerenderKey = ref(0);
    const statusOptions = ref([
      { value: STATUS_ACTIVE, text: vm.$t('myBavActive') },
      { value: STATUS_DELETED, text: vm.$t('myBavRecycleBin') },
    ]);
    const sortingOptions = ref(['createdAt']);
    const statusFilter = ref(STATUS_ACTIVE);
    const categoryFilter = ref('');
    const searchQuery = ref('');
    const searchEmployeeQuery = ref('');
    const selectedDocs = ref([]);
    const documentToDelete = ref(null);
    const documentToEdit = ref(null);
    const selectedEmployee = ref(null);
    const sortingIsDesc = ref(true);
    const grantHrAccessWithUpload = ref(false);
    const uploadDocumentsModal = ref(false);
    const editDocumentsModal = ref(false);
    const archiveDocumentsModal = ref(false);
    const deleteDocumentsModal = ref(false);
    const setRowClass = (item) => {
      for (const document of vm.$store.getters['upload/uploadedData']) {
        if (item.id === document.id) {
          return 'shared-documents--new-row';
        }
      }
    };
    const categoryOptions = computed(() => {
      return EMPLOYEE_CATEGORIES.map((e) => ({
        text: vm.$t(CATEGORY_TRANSLATION_MAP[e]),
        value: e,
      }));
    });
    const loadedEmployees = computed(() => vm.$store.getters['employees/EMPLOYEES_PAGE']);
    const loadedDocuments = computed(() =>
      vm.$store.getters['documents/DOCUMENTS_PAGE'].filter((i) => i.category !== DOC_CUSTOMER_INFO),
    );
    const loadingEmployees = computed(() => vm.$store.state.employees.loading);
    const totalLoadedDocuments = computed(() => vm.$store.state.documents.total);
    const loading = computed(() => vm.$store.state.documents.loading);
    const { activeRole } = vm.$store.getters;
    const headers = computed(() => {
      return [
        {
          text: vm.$t('tableHeaderDocuments'),
          value: 'name',
          sortable: true,
          class: 'text-uppercase',
          width: '25%',
        },
        {
          text: vm.$t('tableHeaderCategory'),
          value: 'category',
          sortable: false,
          class: 'text-uppercase',
          width: '10%',
        },
        {
          text: vm.$t('tableHeaderSharedWith'),
          value: 'employee',
          sortable: false,
          class: 'text-uppercase',
          width: '15%',
        },
        {
          text: vm.$t('tableHeaderTimeDate'),
          value: 'createdAt',
          class: 'text-uppercase',
          width: '15%',
        },

        {
          text: vm.$t('myBavActions'),
          value: 'actions',
          align: 'center',
          sortable: false,
          class: 'text-uppercase',
          width: '10.5%',
        },
      ];
    });

    const fetchDocuments = () => {
      vm.$store.dispatch('documents/fetchAll');
    };

    const confirmEdit = () => {
      categorizeDocument(documentToEdit.value.id, documentToEdit.value.category).then(() => {
        documentToEdit.value = null;
        editDocumentsModal.value = false;

        fetchDocuments();
      });
    };

    const download = (id) => {
      downloadDocument(id);
    };

    const searchData = debounce(function () {
      vm.$store.dispatch('documents/search', searchQuery.value);
    }, 300);

    const getSecurityStatusTranslation = (scanStatus) => {
      return vm.$t(DOCUMENT_SECURITY_SCAN_STATUS_TRANSLATION_MAP[scanStatus]);
    };

    const setStatus = () => {
      vm.$store.dispatch('documents/setStatus', statusFilter.value);
    };

    const setCategory = () => {
      vm.$store.dispatch('documents/filterCategory', categoryFilter.value);
    };

    const setSorting = (fieldName, direction) => {
      vm.$store.commit('documents/CHANGE_SORT_DIRECTION', direction);
      vm.$store.dispatch('documents/sort', fieldName);
    };

    const setLimit = (limit) => {
      vm.$store.dispatch('documents/setLimit', limit);
    };

    const handlePagination = (event) => {
      if (currentPage.value < event.page) onNextPage();
      if (currentPage.value > event.page) onPreviousPage();

      currentPage.value = event.page;
    };

    const onPreviousPage = () => {
      vm.$store.dispatch('documents/previousPage');
    };

    const onNextPage = () => {
      vm.$store.dispatch('documents/nextPage');
    };

    const onDelete = (id) => {
      if (statusFilter.value === STATUS_DELETED) {
        deleteDocumentsModal.value = true;
      } else {
        archiveDocumentsModal.value = true;
      }

      if (id) documentToDelete.value = id;
    };

    const onEdit = (doc) => {
      editDocumentsModal.value = true;
      documentToEdit.value = {
        id: doc.id,
        name: doc.name,
        category: doc.category,
        sharedWithAm: doc.permittedRoles.includes(ROLE_KEY_ACCOUNT),
        sharedWithHr: doc.permittedRoles.includes(ROLE_BAV_MANAGER),
      };
    };

    const onSearchEmployees = () => {
      vm.$store.dispatch('employees/search', {
        search: searchEmployeeQuery.value,
      });
    };

    const confirmArchive = () => {
      if (selectedDocs.value.length > 0) {
        return confirmMultipleArchive();
      }
      return archiveDocument(documentToDelete.value).then(() => {
        archiveDocumentsModal.value = false;
        documentToDelete.value = false;

        fetchDocuments();
      });
    };

    const confirmMultipleArchive = () => {
      const promiseArr = [];

      selectedDocs.value.map(async (i) => {
        promiseArr.push(archiveDocument(i.id));
      });

      Promise.all(promiseArr).then(() => {
        archiveDocumentsModal.value = false;
        documentToDelete.value = false;
        selectedDocs.value = [];

        fetchDocuments();
      });
    };

    const confirmDelete = () => {
      if (selectedDocs.value.length > 0) {
        return confirmMultipleDelete();
      }
      return deleteDocument(documentToDelete.value).then(() => {
        deleteDocumentsModal.value = false;
        documentToDelete.value = false;

        fetchDocuments();
      });
    };

    const confirmMultipleDelete = () => {
      const promiseArr = [];

      selectedDocs.value.map(async (i) => {
        promiseArr.push(deleteDocument(i.id));
      });

      Promise.all(promiseArr).then(() => {
        deleteDocumentsModal.value = false;
        documentToDelete.value = false;
        selectedDocs.value = [];

        fetchDocuments();
      });
    };

    const getDocumentCategoryTranslation = (category) => {
      return vm.$t(CATEGORY_TRANSLATION_MAP[category]);
    };

    onMounted(() => {
      vm.$store.commit('documents/SET_TYPE', 'EMPLOYEE');

      setLimit(15);
    });

    watch(sortingIsDesc, () => {
      if (sortingOptions.value.length > 0) {
        const direction = sortingIsDesc.value ? 'DESC' : 'ASC';
        setSorting(sortingOptions.value[0], direction);
      }
    });

    watch(
      () => vm.$store.state.app.currentLang,
      () => {
        statusOptions.value = [
          { value: STATUS_ACTIVE, text: vm.$t('myBavActive') },
          { value: STATUS_DELETED, text: vm.$t('myBavRecycleBin') },
        ];
        rerenderKey.value += 1;
        vm.$forceUpdate();
      },
    );

    watch(searchEmployeeQuery, () => {
      onSearchEmployees();
    });

    return {
      rerenderKey,
      currentPage,
      headers,
      loadedDocuments,
      searchQuery,
      loading,
      totalLoadedDocuments,
      statusFilter,
      getSecurityStatusTranslation,
      getDocumentCategoryTranslation,
      moment,
      categoryOptions,
      statusOptions,
      categoryFilter,
      searchData,
      sortingOptions,
      sortingIsDesc,
      archiveDocumentsModal,
      deleteDocumentsModal,
      editDocumentsModal,
      setCategory,
      setStatus,
      setLimit,
      handlePagination,
      onDelete,
      onEdit,
      documentToEdit,
      confirmArchive,
      confirmDelete,
      activeRole,
      selectedDocs,
      download,
      confirmEdit,
      onSearchEmployees,
      selectedEmployee,
      searchEmployeeQuery,
      loadingEmployees,
      loadedEmployees,
      grantHrAccessWithUpload,
      ROLE_BAV_MANAGER,
      DOC_CUSTOMER_INFO,
      fetchDocuments,
      setRowClass,

      icons: {
        mdiTrendingUp,
        mdiPlus,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiDownload,
        mdiPencil,
        mdiInformationOutline,
        mdiMagnify,
        mdiInformation,
        mdiDelete,
      },

      validators: {
        required,
      },
    };
  },
};
</script>

<style lang="scss">
.min-width-text {
  min-width: 160px;
}
.min-width-card {
  min-width: 500px;
}
</style>
