<template>
  <div class="py-sm-10 py5">
    <v-row justify="center">
      <v-col cols="12" sm="12" md="10" lg="10">
        <v-card class="v-card--no-border" color="transparent">
          <h1 class="font-weight-bold mb-5 text-center">
            {{ $t('headlineSharedDocuments') }}
          </h1>
          <p class="text-center">
            {{
              $t('messageEmployerDocuments', {
                COMPANY: !!company ? company.companyName : $t('keyAccountYourClientCompaniesEmployeeDocuments'),
              })
            }}
          </p>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <v-col cols="12" md="10" class="mx-auto">
        <shared-documents-data-table> </shared-documents-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getCurrentInstance } from '@vue/composition-api';
import SharedDocumentsDataTable from '@/components/table/SharedDocumentsDataTable.vue';

export default {
  components: { SharedDocumentsDataTable },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('documents/__RESET');
    next();
  },
  setup() {
    const vm = getCurrentInstance().proxy;
    const company = vm.$store.state.auth.companyData;

    return {
      company,
    };
  },
};
</script>

<style scoped></style>
